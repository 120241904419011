/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPayCheck = /* GraphQL */ `
  query GetPayCheck($id: ID!) {
    getPayCheck(id: $id) {
      id
      date
      amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPayChecks = /* GraphQL */ `
  query ListPayChecks(
    $filter: ModelPayCheckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayChecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        amount
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRecurringBill = /* GraphQL */ `
  query GetRecurringBill($id: ID!) {
    getRecurringBill(id: $id) {
      id
      name
      amount
      dueDayOfMo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRecurringBills = /* GraphQL */ `
  query ListRecurringBills(
    $filter: ModelRecurringBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurringBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        amount
        dueDayOfMo
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDebtItem = /* GraphQL */ `
  query GetDebtItem($id: ID!) {
    getDebtItem(id: $id) {
      id
      name
      notes
      balance
      dueDayOfMo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDebtItems = /* GraphQL */ `
  query ListDebtItems(
    $filter: ModelDebtItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDebtItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        notes
        balance
        dueDayOfMo
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      name
      amount
      notes
      paid
      isForDebt
      debtId
      checkId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        amount
        notes
        paid
        isForDebt
        debtId
        checkId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
