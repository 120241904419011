import React, { useState, useEffect } from "react";
import { Amplify, API } from 'aws-amplify';
import ordinal from 'ordinal';
import config from './aws-exports';
import trashCan from './trash-can.svg';
import "@aws-amplify/ui-react/styles.css";

import {
  withAuthenticator,
  Button,
  Expander,
  ExpanderItem,
  Flex,
  View,
  SelectField,
  Tabs,
  TabItem,
  Text
} from "@aws-amplify/ui-react";

import CurrencyInput from 'react-currency-input-field';
import { listDebtItems, listPayments, listPayChecks, listRecurringBills } from "./graphql/queries";
import {
  createPayCheck as createPayCheckMutation,
  deletePayCheck as deletePayCheckMutation,
  updatePayCheck as updatePayCheckMutation,
  createPayment as createPaymentMutation,
  deletePayment as deletePaymentMutation,
  updatePayment as updatePaymentMutation,
  createDebtItem as createDebtItemMutation,
  deleteDebtItem as deleteDebtItemMutation,
  createRecurringBill as createRecurringBillMutation,
  deleteRecurringBill as deleteRecurringBillMutation,
} from "./graphql/mutations";
import './App.css';

Amplify.configure(config);

function App({ signOut }) {
  const [checks, setChecks] = useState([]);
  const [debt, setDebt] = useState([]);
  const [recurringBills, setRecurringBills] = useState([]);
  const [payments, setPayments] = useState([]);
  const [debtPmt, setDebtPmt] = useState(false);

  useEffect(() => {
    fetchChecks();
    fetchPayments();
    fetchDebt();
    fetchRecurringBills();
  }, []);

  // --- Checks API functions ---
  async function fetchChecks() {
    const apiData = await API.graphql({ query: listPayChecks });
    const checksFromAPI = apiData.data.listPayChecks.items;
    setChecks(checksFromAPI);
  }

  async function updatePayCheck(event) {
    event.preventDefault();
    const checkEditForm = new FormData(event.target);
    const amt = checkEditForm.get("amount");
    const floatAmt = Number(amt.replace(/[^0-9.-]+/g,""));
    const data = {
      id: checkEditForm.get("id"),
      date: checkEditForm.get("date"),
      amount: floatAmt,
    };
    await API.graphql({
      query: updatePayCheckMutation,
      variables: { input: data },
    });
    fetchChecks();
    event.target.reset();
  }

  async function createPayCheck(event) {
    event.preventDefault();
    const checkForm = new FormData(event.target);
    const amt = checkForm.get("amount");
    const floatAmt = Number(amt.replace(/[^0-9.-]+/g,""));
    const data = {
      date: new Date(checkForm.get("date")),
      amount: floatAmt,
    };
    await API.graphql({
      query: createPayCheckMutation,
      variables: { input: data },
    });
    fetchChecks();
    event.target.reset();
  }

  async function deletePayCheck({ id }) {
    const newChecks = checks.filter((check) => check.id !== id);
    setChecks(newChecks);
    await API.graphql({
      query: deletePayCheckMutation,
      variables: { input: { id } },
    });
    // delete payments of check
    const checkPmts = payments.filter(pmt => pmt.checkId === id);
    await Promise.all(checkPmts.map((pmt) => deletePayment(pmt))) 
  }
  // ---

  // --- Debt API functions ---
  async function fetchDebt() {
    const apiData = await API.graphql({ query: listDebtItems });
    const debtFromAPI = apiData.data.listDebtItems.items;
    setDebt(debtFromAPI);
  }

  async function createDebtItem(event) {
    event.preventDefault();
    const debtForm = new FormData(event.target);
    const formBalance = debtForm.get("balance");
    const floatBalance = Number(formBalance.replace(/[^0-9.-]+/g,""));
    const data = {
      balance: floatBalance,
      dueDayOfMo: debtForm.get("dueDayOfMo"),
      name: debtForm.get("name"),
      notes: debtForm.get("notes")
    };
    await API.graphql({
      query: createDebtItemMutation,
      variables: { input: data },
    });
    fetchDebt();
    event.target.reset();
  }

  async function deleteDebtItem({ id }) {
    const newDebt = debt.filter((check) => check.id !== id);
    setDebt(newDebt);
    await API.graphql({
      query: deleteDebtItemMutation,
      variables: { input: { id } },
    });
  }
  // ---

  // --- Payment API functions
  async function fetchPayments() {
    const apiData = await API.graphql({ query: listPayments });
    const paymentsFromAPI = apiData.data.listPayments.items;
    setPayments(paymentsFromAPI);
  }

  const handleIsDebtCheckbox = (event) => {
    const isChecked = event.target.checked;
    setDebtPmt(isChecked);
  }

  async function createPayment(event) {
    event.preventDefault();
    const pmtForm = new FormData(event.target);
    const formAmt = pmtForm.get("amount");
    const floatAmt = Number(formAmt.replace(/[^0-9.-]+/g,""));
    const data = {
      amount: floatAmt,
      paid: false,
      name: pmtForm.get("name"),
      checkId: pmtForm.get("checkId"),
      notes: pmtForm.get("notes"),
      isForDebt: debtPmt
    };
    await API.graphql({
      query: createPaymentMutation,
      variables: { input: data },
    });
    fetchPayments();
    event.target.reset();
  }

  async function deletePayment({ id }) {
    const newPayments = payments.filter((pmt) => pmt.id !== id);
    setPayments(newPayments);
    await API.graphql({
      query: deletePaymentMutation,
      variables: { input: { id } },
    });
  }

  const updatePayment = async (pmt) => {
    console.log('pmt:',pmt);
    const data = {
      ...pmt
    }
    await API.graphql({
      query: updatePaymentMutation,
      variables: { input: data },
    });
    fetchPayments();
  }

  const handlePaidUpdate = async (pmt, event) => {
    let paid = event.target.checked;
    const { id, amount, name, checkId, notes, isForDebt } = pmt
    const newPayment = {
      id, amount, name, checkId, notes, isForDebt, paid
    }
    updatePayment(newPayment);
  }
  // ---

  // --- Recurring Bill API functions ---
  async function fetchRecurringBills() {
    const apiData = await API.graphql({ query: listRecurringBills });
    const billsFromAPI = apiData.data.listRecurringBills.items;
    setRecurringBills(billsFromAPI);
  }

  async function createRecurringBill(event) {
    event.preventDefault();
    const billForm = new FormData(event.target);
    const billAmount = billForm.get("amount");

    const floatBillAmount = Number(billAmount.replace(/[^0-9.-]+/g,""));
    const data = {
      name: billForm.get("name"),
      amount: floatBillAmount,
      dueDayOfMo: billForm.get("dueDayOfMo")
    };
    await API.graphql({
      query: createRecurringBillMutation,
      variables: { input: data },
    });
    fetchRecurringBills();
    event.target.reset();
  }

  async function deleteRecurringBill({ id }) {
    const newRecurringBills = recurringBills.filter((bill) => bill.id !== id);
    setRecurringBills(newRecurringBills);
    await API.graphql({
      query: deleteRecurringBillMutation,
      variables: { input: { id } },
    });
  }
  // ---

  return (
    <View className="App">
      <View className="App-nav">
        <Button onClick={signOut}>Sign Out</Button>
      </View>
      <View className="App-content">
        <Tabs justifyContent="center">

          {/* Checks Tab */}
          <TabItem title="Checks">

            <Expander className="App-expander" type="single" isCollapsible={true}>
              <ExpanderItem title="Add Paycheck" value="paycheckToggle">
                <View className="App-formContainer App-payCheckForm" as="form" onSubmit={createPayCheck}>
                  <Flex className="App-form" direction="column" justifyContent="center">
                    
                    <label className="App-formLabel" htmlFor="date">Pay Date</label>
                    <input
                      type="date"
                      name="date"
                      variation="quiet"
                      required
                    />

                    <label className="App-formLabel" htmlFor="amount">Check Amount</label>
                    <CurrencyInput
                      name="amount"
                      prefix="$"
                      defaultValue={0.00}
                      decimalsLimit={2}
                    />

                    <Button type="submit" variation="primary">Add PayCheck</Button>
                  </Flex>
                </View>
              </ExpanderItem>
            </Expander>
          
            {/* List Checks */}
            <View margin="3rem 0">
              {checks.sort((a,b) => { return (new Date(a.date) - new Date(b.date)) } ).map((check) => {
                const checkDate = new Date(check.date);
                const checkDateOffset = new Date(checkDate.getTime() + checkDate.getTimezoneOffset() * 60000);
                const defaultDateValue = checkDateOffset.getFullYear().toString() + '-' + (checkDateOffset.getMonth() + 1).toString().padStart(2, 0) +
                '-' + checkDateOffset.getDate().toString().padStart(2, 0);
                const checkDateString = checkDateOffset.toLocaleDateString("en-US");

                const checkPmts = payments.filter(p => p.checkId === check.id);
                console.log('checkPmts:',checkPmts);
                const remainingCheckAmt = ((check.amount - checkPmts.reduce((a,v) => { return a + v.amount },0)) || check.amount).toLocaleString();

                return (
                  <Expander key={`checkRow-${check.id}`} className="App-expander App-expander--checks" type="single" isCollapsible={true}>
                    <ExpanderItem title={`${checkDateString} - Remaining: $${remainingCheckAmt}`} value={`check-${check.id}`}>

                      {checkPmts.length > 0 ? 
                        (<Flex
                          className="App-billsContainer"
                          direction="column"
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <Flex
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Text className="App-checkPaymentTitle">Bills:</Text>
                          </Flex>
                          <Flex
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            style={{ width: "100%" }}
                          >
                            {checkPmts.map(pmt => (
                              <Flex
                                className="App-checkPaymentItemLabel"
                                key={pmt.id}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                              >

                                <div className="App-paymentRowItem App-paymentRowItem--name">
                                  {pmt.name} ${pmt.amount.toLocaleString()}
                                </div>
                                <div className="App-paymentRowItem App-paymentRowItem--paid">
                                  <input
                                    type="checkbox"
                                    checked={pmt.paid}
                                    onChange={handlePaidUpdate.bind(this, pmt)}
                                    variation="quiet"
                                  />
                                </div>
                                <div className="App-deletePaymentButton App-paymentRowItem App-paymentRowItem--delete" onClick={() => { if(window.confirm('Sure?')) { deletePayment(pmt) } }}>
                                  <img className="App-deleteIcon" src={trashCan} />
                                </div>
                              </Flex>
                            ))}
                          </Flex>
                        </Flex>) : null
                      }

                      <Expander className="App-expander App-expander--checks App-innerExpander" type="single" isCollapsible={true}>
                        <ExpanderItem title="+ Payment" value={`checkPmt-${check.id}`}>
                          <View className="App-formContainer App-payCheckPmtForm" as="form" onSubmit={createPayment}>
                            <Flex
                              className="App-form"
                              direction="column"
                              justifyContent="center"
                            >

                              <input
                                type="text"
                                name="checkId"
                                defaultValue={check.id}
                                variation="quiet"
                                hidden
                                required
                              />

                              <label className="App-formLabel" htmlFor="name">Name</label>
                              <input
                                type="text"
                                name="name"
                                variation="quiet"
                                required
                              />
                              
                              <label className="App-formLabel" htmlFor="date">Is Debt?</label>
                              <input
                                type="checkbox"
                                onChange={handleIsDebtCheckbox}
                                name="isForDebt"
                                variation="quiet"
                              />

                              <label className="App-formLabel" htmlFor="amount">Payment Amount</label>
                              <CurrencyInput
                                name="amount"
                                prefix="$"
                                defaultValue={0.00}
                                decimalsLimit={2}
                              />

                              <Button type="submit" variation="primary">Save</Button>
                            </Flex>
                          </View>
                        </ExpanderItem>
                      </Expander>

                      <Expander className="App-expander App-expander--checks App-innerExpander" type="single" isCollapsible={true}>
                        <ExpanderItem title="Edit Paycheck" value={`checkEdit-${check.id}`}>
                          <Flex
                            key={check.id || check.name}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <View className="App-formContainer App-payCheckEditForm" as="form" onSubmit={updatePayCheck}>
                              <Flex
                                className="App-form"
                                direction="column"
                                justifyContent="center"
                              >

                                <input
                                  type="text"
                                  name="id"
                                  defaultValue={check.id}
                                  variation="quiet"
                                  hidden
                                  required
                                />
                                
                                <label className="App-formLabel" htmlFor="date">Pay Date</label>
                                <input
                                  type="date"
                                  name="date"
                                  defaultValue={defaultDateValue}
                                  variation="quiet"
                                  required
                                />

                                <label className="App-formLabel" htmlFor="amount">Check Amount</label>
                                <CurrencyInput
                                  name="amount"
                                  prefix="$"
                                  defaultValue={check.amount}
                                  decimalsLimit={2}
                                />

                                <Button type="submit" variation="primary">Save</Button>
                              </Flex>
                            </View>
                            <Button className="App-deleteBtn" variation="link" onClick={() => { if(window.confirm('Sure?')) { deletePayCheck(check) } }}>
                              <img className="App-deleteIcon" src={trashCan} />
                            </Button>
                          </Flex>
                        </ExpanderItem>
                      </Expander>
                    </ExpanderItem>
                  </Expander>
                )
              })}
            </View>
          </TabItem>

          {/* Debt Tab */}
          <TabItem title="Debt">

            <Expander className="App-expander" type="single" isCollapsible={true}>
              <ExpanderItem title="Add Debt" value="debtToggle">
                <View className="App-formContainer App-debtForm" as="form" onSubmit={createDebtItem}>
                  <Flex className="App-form" direction="column" justifyContent="center">
                    <label className="App-formLabel" htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      variation="quiet"
                      required
                    />

                    <label className="App-formLabel" htmlFor="balance">Balance</label>
                    <CurrencyInput
                      name="balance"
                      prefix="$"
                      defaultValue={0.00}
                      decimalsLimit={2}
                    />      

                    <label className="App-formLabel" htmlFor="notes">Notes</label>
                    <input
                      type="text"
                      name="notes"
                      variation="quiet"
                    />

                    <SelectField
                      label="Payment Due Day"
                      name="dueDayOfMo"
                    >
                      <option value="1">1st</option>
                      <option value="2">2nd</option>
                      <option value="3">3rd</option>
                      <option value="4">4th</option>
                      <option value="5">5th</option>
                      <option value="6">6th</option>
                      <option value="7">7th</option>
                      <option value="8">8th</option>
                      <option value="9">9th</option>
                      <option value="10">10th</option>
                      <option value="11">11th</option>
                      <option value="12">12th</option>
                      <option value="13">13th</option>
                      <option value="14">14th</option>
                      <option value="15">15th</option>
                      <option value="16">16th</option>
                      <option value="17">17th</option>
                      <option value="18">18th</option>
                      <option value="19">19th</option>
                      <option value="20">20th</option>
                      <option value="21">21th</option>
                      <option value="22">22nd</option>
                      <option value="23">23rd</option>
                      <option value="24">24th</option>
                      <option value="25">25th</option>
                      <option value="26">26th</option>
                      <option value="27">27th</option>
                      <option value="28">28th</option>
                    </SelectField>
                    <Button type="submit" variation="primary">Add Debt Item</Button>
                  </Flex>
                </View>
              </ExpanderItem>
            </Expander>


            {/* List Debt */}
            <View margin="3rem 0">
              {debt.sort((a,b) => a.balance - b.balance).map((debtItem) => (
                <Flex
                  key={debtItem.id || debtItem.name}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ margin: "0px 30px" }}
                >
                  <div style={{ width: "45%", textAlign: "left" }}>
                    <Text as="strong" fontWeight={700}>
                      {debtItem.name}
                    </Text>
                  </div>
                  <div style={{ width: "30%", textAlign: "left" }}>
                    <Text as="span">${debtItem.balance.toLocaleString()}</Text>
                  </div>
                  <div style={{ width: "15%", textAlign: "left" }}>
                    <Button variation="link" onClick={() => { if(window.confirm('Sure?')) { deleteDebtItem(debtItem) } }}>
                      <img className="App-deleteIcon" src={trashCan} />
                    </Button>
                  </div>
                </Flex>
              ))}
            </View>
          </TabItem>

          {/* Recurring Bill Tab */}
          <TabItem title="Recurring Bill">

            <Expander className="App-expander" type="single" isCollapsible={true}>
              <ExpanderItem title="Add Bill" value="recurringBillToggle">
                <View className="App-formContainer App-recurringBillForm" as="form" onSubmit={createRecurringBill}>
                  <Flex className="App-form" direction="column" justifyContent="center">
                    <label className="App-formLabel" htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      variation="quiet"
                      required
                    />

                    <label className="App-formLabel" htmlFor="amount">Amount</label>
                    <CurrencyInput
                      name="amount"
                      prefix="$"
                      defaultValue={0.00}
                      decimalsLimit={2}
                    />

                    <SelectField
                      label="Due Day"
                      name="dueDayOfMo"
                    >
                      <option value="1">1st</option>
                      <option value="2">2nd</option>
                      <option value="3">3rd</option>
                      <option value="4">4th</option>
                      <option value="5">5th</option>
                      <option value="6">6th</option>
                      <option value="7">7th</option>
                      <option value="8">8th</option>
                      <option value="9">9th</option>
                      <option value="10">10th</option>
                      <option value="11">11th</option>
                      <option value="12">12th</option>
                      <option value="13">13th</option>
                      <option value="14">14th</option>
                      <option value="15">15th</option>
                      <option value="16">16th</option>
                      <option value="17">17th</option>
                      <option value="18">18th</option>
                      <option value="19">19th</option>
                      <option value="20">20th</option>
                      <option value="21">21th</option>
                      <option value="22">22nd</option>
                      <option value="23">23rd</option>
                      <option value="24">24th</option>
                      <option value="25">25th</option>
                      <option value="26">26th</option>
                      <option value="27">27th</option>
                      <option value="28">28th</option>
                    </SelectField>
                    <Button type="submit" variation="primary">Add Recurring Bill</Button>
                  </Flex>
                </View>
              </ExpanderItem>
            </Expander>



            {/* List Recurring Bills */}
            <View margin="3rem 0">
              {recurringBills.sort((a,b) => a.dueDayOfMo - b.dueDayOfMo).map((bill) => (
                <Flex
                  key={bill.id || bill.name}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ margin: "0px 30px" }}
                >
                  <div style={{ width: "35%", textAlign: "left" }}>
                    <Text as="strong" fontWeight={700}>
                      {bill.name}
                    </Text>
                  </div>
                  <div style={{ width: "10%", textAlign: "left" }}>
                    <Text fontWeight={500}>
                      {ordinal(Number(bill.dueDayOfMo))}
                    </Text>
                  </div>
                  <div style={{ width: "35%", textAlign: "left" }}>
                    <Text as="span">${bill.amount.toLocaleString()}</Text>
                  </div>
                  <div style={{ width: "20%", textAlign: "left" }}>
                    <Button variation="link" onClick={() => { if(window.confirm('Sure?')) { deleteRecurringBill(bill) } }}>
                      <img className="App-deleteIcon" src={trashCan} />
                    </Button>
                  </div>
                </Flex>
              ))}
            </View>
          </TabItem>
        </Tabs>
      </View>
    </View>
  );
}

export default withAuthenticator(App);
