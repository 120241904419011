/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPayCheck = /* GraphQL */ `
  mutation CreatePayCheck(
    $input: CreatePayCheckInput!
    $condition: ModelPayCheckConditionInput
  ) {
    createPayCheck(input: $input, condition: $condition) {
      id
      date
      amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePayCheck = /* GraphQL */ `
  mutation UpdatePayCheck(
    $input: UpdatePayCheckInput!
    $condition: ModelPayCheckConditionInput
  ) {
    updatePayCheck(input: $input, condition: $condition) {
      id
      date
      amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePayCheck = /* GraphQL */ `
  mutation DeletePayCheck(
    $input: DeletePayCheckInput!
    $condition: ModelPayCheckConditionInput
  ) {
    deletePayCheck(input: $input, condition: $condition) {
      id
      date
      amount
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRecurringBill = /* GraphQL */ `
  mutation CreateRecurringBill(
    $input: CreateRecurringBillInput!
    $condition: ModelRecurringBillConditionInput
  ) {
    createRecurringBill(input: $input, condition: $condition) {
      id
      name
      amount
      dueDayOfMo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRecurringBill = /* GraphQL */ `
  mutation UpdateRecurringBill(
    $input: UpdateRecurringBillInput!
    $condition: ModelRecurringBillConditionInput
  ) {
    updateRecurringBill(input: $input, condition: $condition) {
      id
      name
      amount
      dueDayOfMo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRecurringBill = /* GraphQL */ `
  mutation DeleteRecurringBill(
    $input: DeleteRecurringBillInput!
    $condition: ModelRecurringBillConditionInput
  ) {
    deleteRecurringBill(input: $input, condition: $condition) {
      id
      name
      amount
      dueDayOfMo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDebtItem = /* GraphQL */ `
  mutation CreateDebtItem(
    $input: CreateDebtItemInput!
    $condition: ModelDebtItemConditionInput
  ) {
    createDebtItem(input: $input, condition: $condition) {
      id
      name
      notes
      balance
      dueDayOfMo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDebtItem = /* GraphQL */ `
  mutation UpdateDebtItem(
    $input: UpdateDebtItemInput!
    $condition: ModelDebtItemConditionInput
  ) {
    updateDebtItem(input: $input, condition: $condition) {
      id
      name
      notes
      balance
      dueDayOfMo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDebtItem = /* GraphQL */ `
  mutation DeleteDebtItem(
    $input: DeleteDebtItemInput!
    $condition: ModelDebtItemConditionInput
  ) {
    deleteDebtItem(input: $input, condition: $condition) {
      id
      name
      notes
      balance
      dueDayOfMo
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      name
      amount
      notes
      paid
      isForDebt
      debtId
      checkId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      name
      amount
      notes
      paid
      isForDebt
      debtId
      checkId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      name
      amount
      notes
      paid
      isForDebt
      debtId
      checkId
      createdAt
      updatedAt
      owner
    }
  }
`;
